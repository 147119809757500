import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "enNotfound",
  "title": "404: This page cannot be found",
  "button": {
    "text": "Home",
    "path": "/"
  },
  "endSection": {
    "title": "Learn more about Zimply",
    "button": {
      "text": "Go to",
      "path": "about"
    },
    "imageIcon": "/img/bulb.png"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      